import React from "react";
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Navigation from "./components/Navigation/Navigation";
import HomeTwo from "./components/pages/HomeTwo";
import HomeThree from "./components/pages/HomeThree";
import HomeFour from "./components/pages/HomeFour";
import HomeFive from "./components/pages/HomeFive";
import AboutOne from "./components/pages/AboutOne";
import AboutTwo from "./components/pages/AboutTwo";
import AboutThree from "./components/pages/AboutThree";
import Home from "./components/pages/Home";
import PricingTwo from "./components/pages/PricingTwo";
import SpeakersOne from "./components/pages/SpeakersOne";
import SpeakersTwo from "./components/pages/SpeakersTwo";
import MyNFts from "./components/pages/MyNfts";
import ScheduleOne from "./components/pages/ScheduleOne";
import NftGenerator from "./components/pages/NftGenerator";
import ScheduleThree from "./components/pages/ScheduleThree";
import ScheduleFour from "./components/pages/ScheduleFour";
import Login from "./components/pages/Login";
import Signup from "./components/pages/Signup";
import ComingSoon from "./components/pages/ComingSoon";
import NotFound from "./components/pages/NotFound";
import Faq from "./components/pages/Faq";
import Contact from "./components/pages/Contact";
import BlogOne from "./components/pages/BlogOne";
import BlogTwo from "./components/pages/BlogTwo";
import BlogThree from "./components/pages/BlogThree";
import BlogFour from "./components/pages/BlogFour";
import BlogDetails from "./components/pages/BlogDetails";
import Sponsor from "./components/pages/Sponsor";
import LandEasements from "./components/pages/LandEasements";
import PasswordAccess from "./components/pages/PasswordAccess";
import Layout from "./components/pages/Layout";
import MyRewards from "./components/Rewards/MyRewards";
import Admin from "./components/Admin/Admin";

// Conditionally render Navigation
const renderNavigation = () => {
    if (!(window.location.pathname === '/login' || window.location.pathname === '/signup' || window.location.pathname === '/coming-soon' || window.location.pathname === '/error-404' )){
        return <Navigation />;
    }
}

const AppRouter = () => {

    return (
        <Router>
            <div>
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                    toastOptions={{
                    style: {
                        background: "#00acee",
                        color: "#fff",
                        fontSize: "1.4em",
                        height: 200
                    },
                    }}
            />
            </div>
            {renderNavigation()}
            <Layout>
            <Switch>
                <Route path="/" exact   component={(props) => <PasswordAccess {...props} />}/>
                <Route path="/home" exact  component={(props) => <Home {...props} />}/>
                <Route path="/home-two" exact component={HomeTwo} />
                <Route path="/home-three" exact component={HomeThree} />
                <Route path="/home-four" exact component={HomeFour} />
                <Route path="/home-five" exact component={HomeFive} />
                <Route path="/about-1" exact component={AboutOne} />
                <Route path="/about-2" exact component={AboutTwo} />
                <Route path="/about-3" exact component={AboutThree} />
                <Route path="/pricing-2" exact component={PricingTwo} />
                <Route path="/speakers-1" exact component={SpeakersOne} />
                <Route path="/speakers-2" exact component={SpeakersTwo} />
                <Route path="/admin" exact component={Admin} />
                <Route path="/my-rewards" exact component={MyRewards} />
                <Route path="/my-nfts" exact component={MyNFts} />
                <Route path="/schedule-1" exact component={ScheduleOne} />
                <Route path="/nft-generator" exact component={NftGenerator} />
                <Route path="/schedule-3" exact component={ScheduleThree} />
                <Route path="/schedule-4" exact component={ScheduleFour} />
                <Route path="/login" exact component={Login} />
                <Route path="/signup" exact component={Signup} />
                <Route path="/coming-soon" exact component={ComingSoon} />
                <Route path="/faq" exact component={Faq} />
                <Route path="/contact" exact component={Contact} />
                <Route path="/blog-1" exact component={BlogOne} />
                <Route path="/blog-2" exact component={BlogTwo} />
                <Route path="/blog-3" exact component={BlogThree} />
                <Route path="/blog-4" exact component={BlogFour} />
                <Route path="/single-blog" exact component={BlogDetails} />
                <Route path="/sponsors" exact component={Sponsor} />
                <Route path="/LandEasements" exact component={LandEasements} />
                <Route path="/error-404" exact component={NotFound} />
                <Route component={NotFound} />
            </Switch>
            </Layout>
        </Router>
    );
};

export default AppRouter;