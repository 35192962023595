import { ethers } from "ethers";

const RewardsSingleRow = ({ Reward}) => {

    return (
        <tr>
            <td></td>
            <td> {Reward.beneficiary}</td>
            <td>{ethers.utils.formatEther(Reward.amount.toString())}</td>
            <td>{Reward.erc20TokenAddress}</td>
            <td>
                <button class='btn' onClick={() => {window.open(`https://mumbai.polygonscan.com/address/${Reward.erc20TokenAddress}`)}}>
                    View Token Contract
                </button>
            </td>
        </tr>
    );
}

export default RewardsSingleRow;
