import React from 'react';
import MainBanner from '../AboutTwo/MainBanner';
import Footer from '../Common/Footer';
import ComingSoon from './ComingSoon';

class LandEasements extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MainBanner />
                <ComingSoon />
                <Footer />
            </React.Fragment>
        );
    }
}

export default LandEasements;