import React from 'react';
import AppRouter from './Routes';
import Preloader from './components/Shared/Preloader';
import { AuthProvider } from "./web3/AuthContext";
import {
  EthereumClient,
  modalConnectors,
  walletConnectProvider,
} from "@web3modal/ethereum";

import { Web3Modal } from "@web3modal/react";

import { configureChains, createClient, WagmiConfig, useAccount } from "wagmi";

import { bsc, bscTestnet } from "wagmi/chains";



const App = () =>{
  const chains = [bscTestnet,bsc];
  const [loading, setLoading] = React.useState(true)
  // Wagmi client
  const { provider } = configureChains(chains, [
    walletConnectProvider({ projectId: "6057258fb475f4723b34cdefe54aa345" }),
  ]);
  const wagmiClient = createClient({
    autoConnect: false,
    connectors: modalConnectors({
      projectId: "6057258fb475f4723b34cdefe54aa345",
      version: "1", // or "2"
      appName: "web3Modal",
      chains,
    }),
    provider,
  });
  // React.useEffect(()=>{
  //  demoAsyncCall()
  // },[])
  // const  demoAsyncCall = () => {
  //       // setTimeout(() => {
  //       //   setLoading(false)
  //       // }, 1000);
  //       console.log("teest")
  //   }

  // Web3Modal Ethereum Client
  const ethereumClient = new EthereumClient(wagmiClient, chains);

  return (
    <>
      <WagmiConfig client={wagmiClient}>
          <AuthProvider>
          {/* {loading ? <Preloader/>:<AppRouter /> } */}
          <AppRouter />
          </AuthProvider>
      </WagmiConfig>
      <Web3Modal
        projectId="6057258fb475f4723b34cdefe54aa345"
        ethereumClient={ethereumClient}
      />
    </>
  );
}

export default App;
