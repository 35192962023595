import React, { Children } from 'react';
import {useHistory} from 'react-router-dom';

const Layout = ({children}) => {
let history=useHistory()
React.useEffect(() => {
  if(!window.$isValidUser){
  history.push("/")
   }
}, []);

    return (
        <div>
            {children}
        </div>
    );
}

export default Layout;
