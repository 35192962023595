import React from 'react';
import { Link } from 'react-router-dom';

class MainBanner extends React.Component {
    render(){
        return (
            <div className="custom-page-title-area">
                <div className="container">
                    <h1>Welcome To The Atrium StarWaves</h1>
                    <span>Get Your StarWave Membership NFT</span>
                </div>
            </div>
        );
    }
}
 
export default MainBanner;