import React, { useState, useEffect } from 'react';
import { Link, withRouter, NavLink } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

import { Web3Button, Web3NetworkSwitch } from "@web3modal/react";
import { useAccount } from 'wagmi' 
import { useAuth } from '../../web3/AuthContext';
const Navigation = (props) => {
    const [collapsed, setCollapsed] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const { match } = props;
    const { isConnected, address } = useAccount()
    const [ UserBalance, setUserBalance ]=useState()
    const {balance} = useAuth()

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }


    useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
                window.history.pushState("", document.title, window.location.pathname);
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    }, []);
useEffect(() => {
    console.log(isConnected , "test")
    isConnected&& setUserBalance(balance)
}, [isConnected , balance,address]);
    const toggleOpen = () => setIsOpen(!isOpen);

    useEffect(() => {
        if (props.match.path !== match.path) {
            // onRouteChanged();
            console.log('OK')
        }
    }, [match, props.match.path]);

    const onRouteChanged = () => {
        setIsOpen(!isOpen);
    }

    const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
    const menuClass = `dropdown-menu${isOpen ? " show" : ""}`;

    return (
        <header id="header" className="header-area">
            <div id="navbar" className="elkevent-nav">
                <nav className="navbar navbar-expand-md navbar-light">
                    <div className="container">
                        <Link className="navbar-brand" to="/home">
                            <img src={logo} alt="logo" />
                        </Link>

                        <button
                            onClick={toggleNavbar}
                            className={classTwo}
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className={classOne} id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <Link
                                        exact="true"
                                        to="/home"
                                        onClick={toggleOpen}
                                        className="nav-link"
                                    >
                                        Home
                                    </Link>
                                </li>
                                {isConnected&&(
                                    <>
                                    <li className="nav-item">
                                    <Link
                                        to="/my-nfts"
                                        onClick={toggleOpen}
                                        className="nav-link"
                                    >
                                        Portfolio
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        to="/my-rewards"
                                        onClick={toggleOpen}
                                        className="nav-link"
                                    >
                                        Claimable Rewards
                                    </Link>
                                </li>

                                {address == process.env.REACT_APP_SHOW_ADMIN_WALLET &&                               
                                <li className="nav-item">
                                    <Link
                                        to="/admin"
                                        onClick={toggleOpen}
                                        className="nav-link"
                                    >
                                        Admin
                                    </Link>
                                </li>
                                }
                                {/* <li className="nav-item">
                                <Link
                                    to="/nft-generator"
                                    onClick={toggleOpen}
                                    className="nav-link"
                                >
                                        NFT Generator
                                    </Link>
                                </li> */}
                            </> 
                                )}
                                <li className="nav-item">
                                <Link
                                    to="/LandEasements"
                                    onClick={toggleOpen}
                                    className="nav-link"
                                >
                                        Land Easements
                                    </Link>
                                </li>
                                

                                <li className="nav-item">
                                    <NavLink
                                        to="/contact"
                                        className="nav-link"
                                        onClick={toggleNavbar}
                                    >
                                        Contact
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                {isConnected&&(
                                    <div className='ml-2 section-title mb-0' style={{cursor: "pointer"}} onClick={()=>window.open(`https://mumbai.polygonscan.com/address/${address}`)}>
                                        <i className="icofont-wallet" style={{color: "#FF2D55" , fontSize: 20}}></i>{" "}
                                        {" "}
                                        <span className="d-none d-md-inline">
                                        {UserBalance != ""
                                            ? Number(UserBalance).toFixed(3)
                                            : "loading..."}{" "}
                                        BNB
                                        </span>
                                  </div>
                                )}
                                </li>
                            </ul>

                            <div className="others-option">
                                <ul>
                                    <li>
                                        <Web3Button />                                                                                             
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default withRouter(Navigation);
