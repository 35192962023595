import { deployNewRewardToken } from '../../web3/EmissionUtils';
import toast from "react-hot-toast";
import { ethers } from "ethers";
import { useState } from 'react';

const Admin = () => {
    const [tokenName, setTokenName] = useState();
    const [tokenSymbol, setTokenSymbol] = useState();

    const deployNewToken = async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const chainID = (await provider.getNetwork()).chainId;
        if (chainID == 97) {
            const signer = provider.getSigner();
            const address = await signer.getAddress();
            if(address != process.env.REACT_APP_SHOW_ADMIN_WALLET) {
                toast.error("NOT AUTHORIZED");
                return;
            }
            debugger
            await deployNewRewardToken(tokenName.value, tokenSymbol.value);
        }else{
            toast.error("Wrong Network. Please switch to MUMBAI TESTNET");
        }    
    }

    return (
        <section className="speakers-area-two ptb-120 bg-image" style={{height:'100vh'}}>
            <div>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Type New Token Name"
                    ref={(name)=>{setTokenName(name)}}
                />
                <input
                    type="text"
                    className="form-control"
                    placeholder="Type New Token Symbol"
                    ref={(symbol)=>{setTokenSymbol(symbol)}}
                />
                <button class='btn btn-primary' onClick={() => {deployNewToken()}}>
                    deploy new token
                </button>
            </div>
        </section>
    );
}
export default Admin;
