import React, { useState, useRef } from 'react';

const FoldersUploader = () => {

    const inputRef = useRef(null);
    const [currentModalIndex, setCurrentModalIndex] = useState(null);
    const [nftCopies, setNftCopies] = useState(1)
    const handleFiles = (event) => {
        const files = event.target.files;
        // Access file names and count here
        const file = Array.from(files).map((file) => file);
        if (currentModalIndex !== null) {
            setUiModal((prevUiModal) =>
                prevUiModal.map((item, i) => {
                    if (i === currentModalIndex) {
                        return {
                            ...item,
                            uploadedFilesInThisSection: [
                                ...item.uploadedFilesInThisSection,
                                ...file,
                            ],
                        };
                    }
                    return item;
                })
            );
        }
    };

    const [uiModal, setUiModal] = useState([
        { title: "First Layer", uploadedFilesInThisSection: [] },
        { title: "Second Layer", uploadedFilesInThisSection: [] },
        { title: "Third Layer", uploadedFilesInThisSection: [] },
        { title: "Forth Layer", uploadedFilesInThisSection: [] },
    ]);
    const addNewSection = () => {
        setUiModal([
            ...uiModal,
            { title: "New Layer", uploadedFilesInThisSection: [] },
        ]);
    };

    return (
        <section className="schedule-area bg-image ptb-120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="schedule-slides-item">
                            {uiModal.map((modal, index) => (
                                <div className="single-schedule" key={index}
                                    onClick={() => setCurrentModalIndex(index)}>
                                    <div className="schedule-date" onClick={() => inputRef.current.click()} >
                                        <div className="d-table">

                                            <div className="d-table-cell">
                                                {modal.title}
                                                <span>Select folder</span>
                                            </div>
                                        </div>
                                    </div>
                                    <input
                                        type="file"
                                        id="file-input"
                                        ref={inputRef}
                                        onChange={(event) => handleFiles(event)}
                                        webkitdirectory="true"
                                        style={{ display: 'none' }}
                                    />
                                    <div className="schedule-content">
                                        <div className="author author-multi">
                                            {modal.uploadedFilesInThisSection &&
                                                modal.uploadedFilesInThisSection.length > 0 && (
                                                    modal.uploadedFilesInThisSection.map((elem, index) =>
                                                        <img
                                                            key={index}
                                                            src={URL.createObjectURL(elem)}
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Steven Lucy"
                                                            alt="schedule"
                                                        />
                                                    )
                                                )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                    <div className='d-flex pt-4'>
                        <div className="nft-count-label pt-2">
                            <p>NFT Copies</p>
                        </div>
                        <div className="nft-count-label">
                            <input type={"number"} onChange={(e)=>setNftCopies(e.target.value)} value={nftCopies}/>
                        </div>
                    </div>
                    
                    <div className="col-lg-12">
                        <div className="btn-box">
                            <button onClick={addNewSection} className="btn btn-primary">
                                Add new Layer
                            </button>
                            <button className="btn btn-secondary"
                                onClick={() => console.log(uiModal)}>
                                Generate NFTs
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="shape1">
                <img src={require("../../assets/images/shapes/1.png")} alt="shape1" />
            </div>
            <div className="shape2 rotateme">
                <img src={require("../../assets/images/shapes/2.png")} alt="shape2" />
            </div>
            <div className="shape3 rotateme">
                <img src={require("../../assets/images/shapes/3.png")} alt="shape3" />
            </div>
            <div className="shape4">
                <img src={require("../../assets/images/shapes/4.png")} alt="shape4" />
            </div>
        </section>
    );
}

export default FoldersUploader;