import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LaxDiv from '../Shared/LaxDiv';
import { getNfts } from '../../web3/NFTsUtils';
import { useAccount } from 'wagmi';
import { nftContractAbi, nftContractAddress } from '../../web3/contractConstnse';
import { claimTokenRewards, getTokenRewards } from '../../web3/EmissionUtils';
import { ethers } from 'ethers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/free-solid-svg-icons';

const NFtCards = () => {
    const { address, isConnected } = useAccount()
    const [myNfts, setMyNfts] = useState([])
    const fetchNfts = async () => {
        if (isConnected) {
            const nfts = await getNfts()
            console.log(nfts)
            setMyNfts(nfts)

        }else{
            setMyNfts([])
        }
    }
    const refreshRewards = async(nft, index) => {
        let new_reward = await getTokenRewards(nft.tokenId.toString());
        const resIndex = myNfts?.findIndex(
            ({ tokenId }) => tokenId == nft.tokenId.toString()
          );
          myNfts?.splice(resIndex, 1);
          setMyNfts([
              ...myNfts?.slice(0, resIndex),  
              {...nft,reward:ethers.utils.formatEther(new_reward.toString())},
              ...myNfts?.slice(resIndex),
            ]
          );    
    }
    useEffect(() => {
        fetchNfts()
    }, [isConnected , address])
    const viewInOpenSea = (tokenId) => {
        const openSeaTestnetUrl = `https://testnets.opensea.io/assets/mumbai/${nftContractAddress}/${tokenId}`;
        window.open(openSeaTestnetUrl, "_blank");
      };
    return (
        <section className="speakers-area-two ptb-120 bg-image">
            <div className="container">
                <div className="section-title">
                    <span>Each StarWave is an independent membership My StarWaves</span>
                    <h2>MY NFT Certificates</h2>
                    <div className="bar"></div>
                    <LaxDiv text="MY NFTS" dataPreset="driftRight" />
                    {isConnected&&(<div onClick={()=>window.open(`https://testnets.opensea.io/accounts/${address}/?search[stringTraits][trait_type]=Contract&search[stringTraits][values][0]=${nftContractAddress}`)} className="btn btn-primary">View in opensea</div>)}
                </div>
            </div>
            <div className="container">
                <div className="row">
                    {myNfts.length > 0 ? (
                        myNfts.map((elem, index) =>
                            <div className="col-lg-4 col-md-6" key={index}>
                                <div className="single-speakers-box">
                                    <div>
                                        <img src= {elem.image}alt="speakers" style={{width:"100%"}}></img>
                                    </div>
                                    <div className="speakers-content">
                                        <h3><Link to="#">{elem.name}</Link></h3>
                                        <span>{elem.nftType}</span>
                                        <span style={{marginBottom: '12px'}}>
                                            current reward: {elem.reward} SWA {'  '}
                                            <FontAwesomeIcon icon={faRefresh} onClick={()=>{refreshRewards(elem, index)}} color='blue'/>
                                        </span>
                                        <span>
                                            <button class="btn btn-primary" onClick={() => {claimTokenRewards(parseInt(elem.tokenId), elem.reward, false)}}>Claim SWA Token</button>
                                            {''}
                                            <button class="btn btn-primary" onClick={() => {claimTokenRewards(parseInt(elem.tokenId), elem.reward, true)}}>Claim & Swap to SFMC</button>
                                        </span>

                                        <ul className="social">
                                            <li>
                                                <Link to="#" onClick={()=>viewInOpenSea(elem.tokenId)}><i className="icofont-eye-alt "></i></Link>
                                            </li>
                                            <li>
                                                <Link to="#" ><i className="icofont-shopping-cart"></i></Link>
                                            </li>
                                            {/* <li>
                                                <Link to="https://www.instagram.com/" target="_blank"><i className="icofont-instagram"></i></Link>
                                            </li>
                                            <li>
                                                <Link to="https://www.linkedin.com/" target="_blank"><i className="icofont-linkedin"></i></Link>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div> 
                                {/* claimTokenRewards */}
                            </div>
                        )
                    ) : (
                        <div className="container">
                            <div className="section-title">
                                <LaxDiv text="No NFTs" dataPreset="driftCenter" />
                            </div>
                        </div>
                    )}


                </div>
            </div>
        </section>
    );
}

export default NFtCards;