import React,{useState } from 'react';
import { Mint } from '../../web3/NFTsUtils';
import { useAccount } from "wagmi";
import { useAuth } from '../../web3/AuthContext';
const PricingCardOne = ()=>{
    const {balance , refreshBalance} = useAuth()
const mintNewNft = async() => {
    setLoading(true)
    try{
        const mint =  await Mint(1)
        if(mint){
        setTimeout(() => {
            refreshBalance()
            setLoading(false) 
        }, 1000);
          
        }            
    }catch(e){
        console.log(e);
        setLoading(false)
    }
}
const {isConnected} = useAccount()
const [loading ,  setLoading] = useState(false)
const disabledWave = true
    return (
        <section className="pricing-area ptb-120 bg-image">
            <div className="">
                <div className="row justify-content-center">
                    <div className="col-lg-2 col-md-6 d-flex">
                        <div className="pricing-table-box">
                            <div className="pricingTable-header">
                                <h3 className="title">StarWave 0 </h3>
                                <div className="price-value"><sup>K {" "}</sup>2 $</div>
                            </div>
                            <ul className="pricing-content">
                                <img src={"https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmbZkpUVuve7BEoZ5vpguHttkeEiAVmhRB6EjrxtiQziwp/StarWave%200%20400pxW%20x%20479pxH.gif"}
                                className="p-2"/>
                            </ul>
                            <button 
                            className="btn btn-primary"
                            disabled={!isConnected || loading}
                            onClick={()=>mintNewNft()}
                            >
                                Mint NFT Now
                            </button>
                              {loading&&(
                                <div className="customPreloader">
                                  <div className="loader"></div>
                                </div> 
                            )}                             
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-6">
                        <div className="pricing-table-box" style={{background: disabledWave ? 'rgba(255, 255, 255, 0.5)' : 'transparent', opacity: disabledWave ? 0.5 : 1  , pointerEvents: disabledWave ? 'none':'auto'}}>
                            <div className="pricingTable-header">
                                <h3 className="title">StarWave 1</h3>
                                <div className="price-value"><sup>K {" "}</sup>3 $</div>
                            </div>
                            
                            <ul className="pricing-content">
                                <img src={"https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmbZkpUVuve7BEoZ5vpguHttkeEiAVmhRB6EjrxtiQziwp/StarWave%201%20400pxW%20x%20479pxH.gif"}
                                className="p-2"/>
                            </ul>
                            
                            <button 
                            className="btn btn-primary"
                            disabled={true}
                            onClick={()=>console.log(isConnected)}
                            >
                            Coming Soon!
                            </button>
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-6 offset-lg-0 offset-md-3">
                        <div className="pricing-table-box" style={{background: disabledWave ? 'rgba(255, 255, 255, 0.5)' : 'transparent', opacity: disabledWave ? 0.5 : 1  , pointerEvents: disabledWave ? 'none':'auto'}}>
                            <div className="pricingTable-header">
                                <h3 className="title">StarWave 2</h3>
                                <div className="price-value"><sup>K {" "}</sup>5 $</div>
                            </div>
                            
                            <ul className="pricing-content">
                                <img src={"https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmbZkpUVuve7BEoZ5vpguHttkeEiAVmhRB6EjrxtiQziwp/StarWave%202%20400pxW%20x%20479pxH.gif"}
                                className="p-2"/>
                            </ul>
                            <button 
                            className="btn btn-primary"
                            disabled={true}
                            onClick={()=>console.log(isConnected)}
                            >
                            Coming Soon!
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 offset-lg-0 offset-md-3">
                        <div className="pricing-table-box"  style={{background: disabledWave ? 'rgba(255, 255, 255, 0.5)' : 'transparent', opacity: disabledWave ? 0.5 : 1  , pointerEvents: disabledWave ? 'none':'auto'}}>
                            <div className="pricingTable-header">
                                <h3 className="title">StarWave 3</h3>
                                <div className="price-value"><sup>K {" "}</sup>7 $</div>
                            </div>
                            
                            <ul className="pricing-content">
                                <img src={"https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmbZkpUVuve7BEoZ5vpguHttkeEiAVmhRB6EjrxtiQziwp/StarWave%203%20400pxW%20x%20479pxH.gif"}
                                className="p-2"/>
                            </ul>
                            <button 
                            className="btn btn-primary"
                            disabled={true}
                            onClick={()=>console.log(isConnected)}
                            >
                            Coming Soon!
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 offset-lg-0 offset-md-3 ">
                        <div className="pricing-table-box"  style={{background: disabledWave ? 'rgba(255, 255, 255, 0.5)' : 'transparent', opacity: disabledWave ? 0.5 : 1  , pointerEvents: disabledWave ? 'none':'auto'}}>
                            <div className="pricingTable-header">
                                <h3 className="title">StarWave 4</h3>
                                <div className="price-value"><sup>K {" "}</sup>9 $</div>
                            </div>                            
                            <ul className="pricing-content">
                                <img src={"https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmbZkpUVuve7BEoZ5vpguHttkeEiAVmhRB6EjrxtiQziwp/StarWave%204%20400pxW%20x%20479pxH.gif"}
                                className="p-2"/>
                            </ul>
                            <button 
                            className="btn btn-primary"
                            disabled={true}
                            onClick={()=>console.log(isConnected)}
                            >
                            Coming Soon!
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default PricingCardOne;