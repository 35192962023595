import React, { createContext, useState, useEffect } from 'react'
import axios from 'axios'
import { useAccount, useDisconnect } from 'wagmi' 
import { ethers } from 'ethers'

interface IEntityInfo {
  publicKey: string
}

//interface of the context object
interface IAuthContextValue {
  isLoggedIn: boolean
  balance: string
  entityInfo: IEntityInfo
  email: string | null
  token: string | null
  signType: string | null
  signProviderName: string | null //this var is using to switch between login providers icons in header
  dialogBoxOpen: boolean,
  setSignProviderName: (signProviderName: string) => void //func to detect login provider name changes
  setEmail: (email: string) => void
  setToken: (token: string) => void
  setSignType: (signType: string) => void
  login: () => void
  logout: () => void
  refreshAuth: () => void
  refreshBalance: () => void
  setIsLoggedIn: (isLoggedIn: boolean, pubKey: string | null) => void
  setDialogBoxOpen: (value: boolean) => void
}



//inital values for the context object
const AuthContext = createContext<IAuthContextValue>({
  isLoggedIn: false,
  balance: "",
  entityInfo: { publicKey: "" },
  email: null,
  token: null,
  signType: null,
  signProviderName: null,
  dialogBoxOpen: false,
  setEmail: () => {},
  setToken: () => {},
  setSignType: () => {},
  login: () => {},
  logout: () => {},
  refreshAuth: () => {},
  refreshBalance: () => {},
  setIsLoggedIn: () => {},
  setSignProviderName: () => {},
  setDialogBoxOpen: () => {},
})

const AuthProvider = (props: any) => {

  const { isConnected, address } = useAccount()
  const { disconnect } = useDisconnect()

  const [loggedIn, setLoggedIn] = useState(false);
  const [balance, setBalance] = useState("");
  const [entityInfo, setEntityInfo] = useState<IEntityInfo>({
    publicKey: "",
  })
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [signType, setSignType] = useState('');
  const [signProviderName, setSignProviderName] = useState("");
  
  //a flag to indicate to open or close the dialog box
  const [dialogBoxOpen, setDialogBoxOpen] = useState(false);

  const {
    REACT_APP_WALLET_API_BASE_URL,
    REACT_APP_WALLET_API_ENV,
  } = process.env


  async function updateLoginStatus(
    isLoggedIn: boolean,
    pubKey: string | any,
    walletType: string = 'MUMBAI',
    email: string = '',
    password: string = ''
  ) {
    setEntityInfo({ publicKey: pubKey })
    setSignType(walletType)
    setLoggedIn(isLoggedIn)
  }

  function refreshEntityInfo() {
    if (loggedIn) setEntityInfo({ publicKey: entityInfo.publicKey })
    else setEntityInfo({ publicKey: "" })
  }

  async function refreshEntityBalance() {
    //refresh after purchase
    if (isConnected)
      updateLoginStatus(true, address, "MUMBAI")
  }

  useEffect(() => {
    if (isConnected)
      updateLoginStatus(true, address, "MUMBAI")


  }, [isConnected , address])

  const login = async () => {
    
  }

  const logout = () => {
    if (!loggedIn) {
      console.log('Already logged out !')
      return false
    }
    if (signType === 'social') {
      setEntityInfo({ publicKey: "" })
      setSignType('')
      setLoggedIn(false)
    } else {
      setEntityInfo({ publicKey: "" })
      setSignType('')
      setLoggedIn(false)
      disconnect()
    }
    return true
  }

  const contextValue: IAuthContextValue = {
    isLoggedIn: loggedIn,
    balance,
    entityInfo,
    login,
    logout,
    email,
    token,
    signType,
    signProviderName,
    dialogBoxOpen,
    refreshAuth: refreshEntityInfo,
    refreshBalance: refreshEntityBalance,
    setEmail: setEmail,
    setSignProviderName: setSignProviderName,
    setToken: setToken,
    setSignType: setSignType,
    setIsLoggedIn: updateLoginStatus,
    setDialogBoxOpen: setDialogBoxOpen,
  }

  useEffect(() => {
    if (signType === 'social') {
      console.log(
        '\n\nAuth details changed\n',
        {
          isLoggedIn: loggedIn,
          signType: signType,
          pubKey: entityInfo.publicKey,
          email: email,
        },
        '\n\n\n',
      )
    } else {
      console.log(
        '\n\nAuth details changed\n',
        {
          isLoggedIn: loggedIn,
          signType: signType,
          pubKey: entityInfo.publicKey,
        },
        '\n\n\n',
      )

    }

    if (entityInfo.publicKey !== "" && loggedIn) {
      const provider = new ethers.providers.Web3Provider(window.ethereum as any);
      provider.getBalance(entityInfo.publicKey).then((balance) => { setBalance(ethers.utils.formatEther(balance)) });

    }
    
  }, [loggedIn, entityInfo, email, signType, balance, address])

  return <AuthContext.Provider value={contextValue} {...props} />
}

const useAuth = () => React.useContext(AuthContext)

export { AuthProvider, useAuth }
