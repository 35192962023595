import LaxDiv from '../Shared/LaxDiv';
import { useAccount } from 'wagmi';
import { nftEmissionSystemAddress } from '../../web3/contractConstnse';
import { useEffect, useState } from 'react';
import { getUserPreviousRewards } from '../../web3/EmissionUtils';
import RewardsSingleRow from './RewardsSingleRow';
import { claimUserPreviousPayments } from '../../web3/EmissionUtils';
const MyRewards = () => {
    const { address, isConnected } = useAccount()
    const [ claimableRewards, setClaimableRewards ] = useState([]);

    useEffect(() => {
        if(isConnected) {
            (async () => {
                const userClaimableRewards = await getUserPreviousRewards();
                setClaimableRewards(userClaimableRewards);
            })();
        }else{
            setClaimableRewards([])
        } 
    }, [getUserPreviousRewards]) 

    return (
        <section className="speakers-area-two ptb-120 bg-image" style={{height:'100vh'}}>
            <div className="container">
                <div className="section-title">
                    <span>This page includes all your unclaimed rewards from previous SWA tokens</span>
                    <h2>My Claimable Rewards</h2>
                    <div className="bar"></div>
                    <LaxDiv text="MY NFTS" dataPreset="driftRight" />
                    {isConnected&&(<div onClick={()=>window.open(`https://mumbai.polygonscan.com/address/${nftEmissionSystemAddress}`)} className="btn btn-primary">View Emission Contract in Explorer</div>)}
                </div>
            </div>
            <table className="table" style={{color:'white'}}>
                <thead>
                    <tr>
                        <th></th>
                        <th scope='col'> Beneficiary </th>
                        <th scope='col'> Amount </th>
                        <th scope='col'> SWA token address</th>
                        <th scope='col'> 
                            <button class="btn btn-primary" onClick={() => {claimUserPreviousPayments()}}>
                                Claim Previous Rewards
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {claimableRewards.length > 0 && claimableRewards?.map((Reward) => {
                        return (
                            <RewardsSingleRow 
                            Reward={Reward}
                        />
                        );
                    })}
                </tbody>
            </table>
        </section>
    );
}

export default MyRewards;