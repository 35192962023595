import React from 'react';
import MainBanner from '../Nfts/MainBanner';
import NFtCards from '../Nfts/NftCards';
import Footer from '../Common/Footer';
 
class MyNFts extends React.Component {
    render(){
        return (
            <React.Fragment>
                <MainBanner />
                <NFtCards />
                <Footer />
            </React.Fragment>
        );
    }
}
 
export default MyNFts;