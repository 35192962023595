import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './assets/css/bootstrap.min.css';
import './assets/css/icofont.min.css';
import './assets/css/animate.min.css';
import '../node_modules/react-modal-video/css/modal-video.min.css'
import './assets/css/style.css';
import './assets/css/responsive.css';

ReactDOM.render(<App />, document.getElementById('root'));
