import { Contract, Signer } from "ethers";
import { ethers } from "ethers";
import toast from "react-hot-toast";
// import { nftContractABI, nftContractAddress } from "./contractConstants";
import { nftContractAbi, nftContractAddress } from "./contractConstnse";
import { getTokenRewards } from "./EmissionUtils";
// Connect to the Polygon Mumbai testnet
// const provider = new ethers.providers.MumbaiProvider();
// const signer = provider.getSigner();

export async function Mint(choice) {
  // Get the Metamask provider
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const chainID = (await provider.getNetwork()).chainId;
  if (chainID == 97) {
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    // Connect to the Contract
    const contract = new Contract(nftContractAddress, nftContractAbi, signer);
    let metedata;
    let name;
    let nftType;
    let price;
    let image;
    let denomination;
    if (choice === 1) {
      metedata =
        "https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmT46nZv8veyWVjuYSvh4TfVoUg9BGHPtawiETjfnp25fe/0.json";
      name = "NFT 1";
      nftType = "StarWave 0";
      price = 0.10;
      image =
        "https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmbZkpUVuve7BEoZ5vpguHttkeEiAVmhRB6EjrxtiQziwp/StarWave%200%20400pxW%20x%20479pxH.gif";
      denomination = 1;
    } else if (choice === 2) {
      metedata =
        "https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmT46nZv8veyWVjuYSvh4TfVoUg9BGHPtawiETjfnp25fe/1.json";
      name = "NFT 2";
      nftType = "StarWave 1";
      price = "0.50";
      image =
        "https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmbZkpUVuve7BEoZ5vpguHttkeEiAVmhRB6EjrxtiQziwp/StarWave%201%20400pxW%20x%20479pxH.gif";
        denomination = 2;
    } else if (choice === 3) {
      metedata =
        "https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmT46nZv8veyWVjuYSvh4TfVoUg9BGHPtawiETjfnp25fe/2.json";
      name = "NFT 3";
      nftType = "StarWave 2";
      price = "0.10";
      image =
        "https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmbZkpUVuve7BEoZ5vpguHttkeEiAVmhRB6EjrxtiQziwp/StarWave%202%20400pxW%20x%20479pxH.gif";
      denomination = 3;
    }else if (choice === 4) {
      metedata =
        "https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmT46nZv8veyWVjuYSvh4TfVoUg9BGHPtawiETjfnp25fe/3.json";
      name = "NFT 4";
      nftType = "StarWave 3";
      price = "0.10";
      image =
        "https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmbZkpUVuve7BEoZ5vpguHttkeEiAVmhRB6EjrxtiQziwp/StarWave%203%20400pxW%20x%20479pxH.gif";
        denomination = 4;
    }else if (choice === 5) {
      metedata =
        "https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmT46nZv8veyWVjuYSvh4TfVoUg9BGHPtawiETjfnp25fe/3.json";
      name = "NFT 4";
      nftType = "StarWave 4";
      price = "0.10";
      image =
        "https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmbZkpUVuve7BEoZ5vpguHttkeEiAVmhRB6EjrxtiQziwp/StarWave%204%20400pxW%20x%20479pxH.gif";
      denomination = 5;
    }
    //Call the "mint" function on the Contract

    const displayMessageWithLink = (hash) => (
      <div>
        Minting success! for transaction{" "}
        <a
          href={`https://mumbai.polygonscan.com/tx/${hash}`}
          target="_blank"
          rel="noreferrer"
        >
          Click Here
        </a>
      </div>
    );
    try {
      console.log(price , " Price Price ")
      let p = ethers.utils.parseUnits("0.01", "ether");
      const tx = await contract.safeMint(
        address,
        metedata,
        name,
        image,
        nftType,
        denomination,
        // ethers.utils.parseEther(price.toString()), 
        {
          value: ethers.utils.parseEther(price.toString())
        }
      );
      toast.promise(tx.wait(), {
        loading: "Minting NFT",
        success: displayMessageWithLink(tx.hash),
        error: "Minting failed",
      },{duration:5000})
      const receipt = await tx.wait();
      if (receipt.status === 1) {
        return true
      }else{
        return false
      }
    } catch (e) {
      toast.error("Minting failed" );
      console.log(e , "mint err")
      return false
    }
  } else {
    toast.error("Please connect to Mumbai Testnet");
  }
}

export const getNfts = async () => {
  debugger;
  
  // Get the Metamask provider
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const address = await signer.getAddress();

  // Connect to the Contract
  const contract = new Contract(nftContractAddress, nftContractAbi, signer);
  let user_nfts = await contract.getUserTokens(address);
  let nfts = [];
  for(let i = 0; i < user_nfts.length; i++) {
    let reward = await getTokenRewards(parseInt(user_nfts[i].tokenId));
    reward = ethers.utils.formatEther( reward );
    nfts.push({...user_nfts[i], reward})
  }

  return nfts;
};
