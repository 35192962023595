import { Contract, Signer } from "ethers";
import { ethers } from "ethers";
import { nftEmissionSystemAddress, nftEmissionSystemABI } from "./contractConstnse";
import toast from "react-hot-toast";

export async function claimTokenRewards(tokenId, reward, isSwapToSFMC) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const chainID = (await provider.getNetwork()).chainId;
    if (chainID == 97) {
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        const contract = new Contract(nftEmissionSystemAddress, nftEmissionSystemABI, signer);
        try{
            await contract.chargeNft(tokenId, ethers.utils.parseEther(reward), address, isSwapToSFMC);
        }catch(e){
            toast.error("Error claiming your rewards!");
        }
    }else{
        toast.error("Error claiming your rewards");
    }
}

export async function deployNewRewardToken(tokenName, tokenSymbol) {
    // updateEmissionErc20Token(string memory name, string memory symbol)
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const chainID = (await provider.getNetwork()).chainId;
    if (chainID == 97) {
        const signer = provider.getSigner();
        // const address = await signer.getAddress();
        const contract = new Contract(nftEmissionSystemAddress, nftEmissionSystemABI, signer);
        try{
            await contract.updateEmissionErc20Token(tokenName, tokenSymbol);
        }catch(e){
            console.log("Error updating contract, ", e);
            toast.error("Error updating rewards contract!");
        }
    }else{
        toast.error("Error claiming your rewards");
    }
}

export async function getUserPreviousRewards() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const chainID = (await provider.getNetwork()).chainId;
    debugger
    if (chainID == 97) {
        const signer = provider.getSigner();
        // const address = await signer.getAddress();
        const contract = new Contract(nftEmissionSystemAddress, nftEmissionSystemABI, signer);
        try{
            const res = await contract.getUserPreviousPayments();
            return res;
        }catch(e){
            console.log("error loading rewards", e);
            toast.error("Error getting your rewards!");
        }

    }else{
        toast.error("Error claiming your rewards");
    }
}

export async function claimUserPreviousPayments() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const chainID = (await provider.getNetwork()).chainId;
    if (chainID == 97) {
        const signer = provider.getSigner();
        // const address = await signer.getAddress();
        const contract = new Contract(nftEmissionSystemAddress, nftEmissionSystemABI, signer);
        try{
            await contract.claimPreviousRewards();
        }catch(e){
            toast.error("Error getting claiming your rewards!");
        }
    }else{
        toast.error("Error claiming your rewards");
    }
}

export async function getTokenRewards(tokenId) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const chainID = (await provider.getNetwork()).chainId;
    if (chainID == 97) {
        const signer = provider.getSigner();
        // const address = await signer.getAddress();
        const contract = new Contract(nftEmissionSystemAddress, nftEmissionSystemABI, signer);
        let rewards;
        try{
            rewards = await contract.getNftClaimableRewards(tokenId);
        }catch(e){
            toast.error("Error fetching token rewards");
        }

        return rewards;
    }else{
        toast.error("Please connect to Mumbai Testnet");
    }
}