import React from 'react';
import {useHistory, withRouter} from 'react-router-dom';

const PasswordAccess = () => {
    const [password, setPassword] = React.useState("");
let history=useHistory()
    const navigate = () => {
        if (password.length > 0) {
            if (password === "Certificate@2023") {
                window.$isValidUser = true;
                history.push("/home");
            } else {
                window.alert("Wrong password");
                window.$isValidUser = false;
            }
        }
    };

    return (
        <div className="error-area password-screen">
            <div className="d-table">
                <div className="d-table-cell">
                    <h2 className='text-white'>Access Restricted
                    </h2>
                    <h2 className='text-white'>Please Enter The Password</h2>
                        <div className="form-group">
                            <input type='password'
                                value={password}
                                className="form-control"
                                placeholder="Enter Password here!"
                                onChange={
                                    (e) => setPassword(e.target.value)
                                }/>
                        </div>

                        <button onClick={()=>{navigate()}} className="btn btn-primary">Submit</button>
                </div>
            </div>
        </div>
    );
}

export default PasswordAccess;
