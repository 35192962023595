import React from 'react';
import { Link } from 'react-router-dom';

class MainBanner extends React.Component {
    render(){
        return (
            <div className="custom-page-title-area item-bg2">
                <div className="container">
                    <h1>Land Easements</h1>
                    <span>Coming soon</span>
                    
                </div>
            </div>
        );
    }
}
 
export default MainBanner;